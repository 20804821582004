var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"dialog":_vm.dialog,"title":"Expense Dialog","current":"","dialogWidth":1024},on:{"close":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.updateDialog ? "Update" : "Add")+" Expense "),_c('span',{staticClass:"orange--text text--darken-4 barcode-font-size"},[_vm._v("  #"+_vm._s(_vm.expense.barcode))])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"min-height-280"},[_c('v-form',{ref:"expenseForm",attrs:{"lazy-validation":""}},[_c('v-row',[(false)?_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('label',{staticClass:"btx-label mt-1 required",attrs:{"for":"barcode"}},[_vm._v("Expence Claim #")])]):_vm._e(),(false)?_c('v-col',{staticClass:"py-0",attrs:{"md":"10"}},[_c('TextInput',{class:{
								required: !_vm.expense.barcode,
							},attrs:{"hide-details":"","disabled":_vm.pageLoading || _vm.updateDialog ? true : false,"loading":_vm.pageLoading,"id":"barcode","placeholder":"barcode","hide-top-margin":"","rules":[_vm.vrules.required(_vm.expense.barcode, 'Expence Claim #'), _vm.barcodeError ? false : true]},on:{"change":function($event){return _vm.validateBarcode(_vm.expense.barcode, 'claim-expense')}},model:{value:(_vm.expense.barcode),callback:function ($$v) {_vm.$set(_vm.expense, "barcode", $$v)},expression:"expense.barcode"}})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('label',{staticClass:"btx-label mt-4 required",attrs:{"for":"title"}},[_vm._v("Title")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"10"}},[_c('TextInput',{class:{
								required: !_vm.expense.title,
							},attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"title","placeholder":"Title","rules":[_vm.vrules.required(_vm.expense.title, 'Title'), _vm.barcodeError ? false : true]},model:{value:(_vm.expense.title),callback:function ($$v) {_vm.$set(_vm.expense, "title", $$v)},expression:"expense.title"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('label',{staticClass:"btx-label mt-4 required",attrs:{"for":"customer"}},[_vm._v("Customer")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"10"}},[_c('SelectCustomerSupplier',{class:{
								required: !_vm.customer.id,
							},attrs:{"items":_vm.customerList,"update-detail":_vm.selectedCustomer,"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"type":"customer","rules":[_vm.vrules.required(_vm.customer.id, 'customer')],"placeholder":"Customer","service-address":false,"input-id":"customer-id"},on:{"change":function($event){return _vm.getRelatedProject($event)}},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('label',{staticClass:"btx-label mt-4 required",attrs:{"for":"project"}},[_vm._v("Project")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"10"}},[_c('AutoCompleteInput',{class:{
								required: !_vm.expense.project,
							},attrs:{"hide-details":"","items":_vm.projectList,"placeholder":"Project","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.expense.project, 'project')]},on:{"change":function($event){return _vm.getRelatedBudget($event)}},model:{value:(_vm.expense.project),callback:function ($$v) {_vm.$set(_vm.expense, "project", $$v)},expression:"expense.project"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('label',{staticClass:"btx-label mt-4",attrs:{"for":"Budget_catgory"}},[_vm._v("Budget Category")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"10"}},[_c('SelectBudget',{attrs:{"hide-details":"","return-object":"","items":_vm.budgetCategoryList,"placeholder":"Budget Category","disabled":_vm.pageLoading,"loading":_vm.pageLoading},model:{value:(_vm.budgetCategory),callback:function ($$v) {_vm.budgetCategory=$$v},expression:"budgetCategory"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('label',{staticClass:"btx-label mt-3",attrs:{"for":"remark"}},[_vm._v("Remark")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"10"}},[_c('TextAreaInput',{attrs:{"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"remark","placeholder":"Remark"},model:{value:(_vm.expense.remark),callback:function ($$v) {_vm.$set(_vm.expense, "remark", $$v)},expression:"expense.remark"}})],1)],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"depressed":"","tile":"","disabled":_vm.pageLoading},on:{"click":function($event){return _vm.$emit('close', false)}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"blue darken-4","tile":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading},on:{"!click":function($event){$event.preventDefault();return _vm.updateOrCreate()}}},[_vm._v(" "+_vm._s(_vm.updateDialog ? "Update" : "Save")+" ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }